<template>
    <v-container fluid tag="section">

        <div class="text-end">
            <v-btn outlined class="font-weight-bold me-2" :to="`/plans`">{{ $t('Cancel') }}</v-btn>
            <v-btn color="purple" class="font-weight-bold me-0" @click="onSubmit" :loading="loading"
                :disabled="loading">{{ $t('Save') }}</v-btn>
        </div>

        <v-row>
            <v-col cols="12">
                <v-card class="rounded-lg mt-2">
                    <v-card-text class="px-0 py-0">
                        <v-form>
                            <v-container>
                                <v-text-field v-model="item.name" :label="$t('Name')"></v-text-field>
                                <v-textarea v-model="item.desc" :label="$t('Description')"></v-textarea>
                                <v-row>
                                    <v-col>
                                        <v-select v-model="item.pricing_type_id" :label="$t('Pricing Type')"
                                            :items="pricingTypes" item-text="name" item-value="id"></v-select>
                                    </v-col>
                                    <v-col v-if="item.pricing_type_id == 2">
                                        <v-select v-model="item.pricing_interval_id" :label="$t('Pricing Interval')"
                                    :items="pricingIntervals" item-text="name" item-value="id"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="2">
                                        <v-text-field v-model="item.currency" :label="$t('Currency')" 
                                        persistent-hint hint="Three-letter ISO currency code, in lowercase."></v-text-field>
                                    </v-col>
                                    <v-col cols="10">
                                        <v-text-field v-model="item.price" :label="$t('Price')"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-text-field v-model="item.credit" :label="$t('Credit')"></v-text-field>
                                <v-text-field v-model="item.stripe_id" :label="$t('Stripe Price Id')"></v-text-field>
                                <v-text-field v-model="item.revenuecat_offering_id" :label="$t('Revenuecat Offering Id')"></v-text-field>
                                <v-text-field v-model="item.revenuecat_ios_product_id" :label="$t('Revenuecat IOS Product Id')"></v-text-field>
                                <v-text-field v-model="item.revenuecat_android_product_id" :label="$t('Revenuecat Android Product Id')"></v-text-field>
                                <v-text-field v-model="item.metadata.recommendation_text" :label="$t('Recommendation Text')"></v-text-field>
                                <v-checkbox v-model="item.is_active" :label="$t('Active')"></v-checkbox>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import planService from '@/services/planService'

export default {
    data() {
        return {
            loading: false,
            item: {
                name: null,
                description: null,
                pricing_type_id: 2,
                pricing_interval_id: 3,
                price: null,
                currency: 'try',
                credit: null,
                metadata: {
                    recommendation_text: null
                },
                is_active: true
            },
            pricingTypes: [
                {id: 1, name: 'One Time'},
                {id: 2, name: 'Recurring'},
            ],
            pricingIntervals: [
                {id: 1, name: 'Day'},
                {id: 2, name: 'Week'},
                {id: 3, name: 'Month'},
                {id: 4, name: 'Year'},
            ]
        }
    },
    async created() {
        if (this.$route.params.id) {
            await this.getPlan(this.$route.params.id)
        }
    },
    methods: {
        async getPlan(id) {
            try {
                const result = await planService.get(id)
                result.data.metadata = {...this.item.metadata, ...result.data.metadata}
                this.item = result.data
            } catch (error) {
                this.$dialog.message.error(error.message)
                this.$router.push('/plans')
            }
        },
        async onSubmit() {
            this.loading = true
            try {
                if (this.$route.params.id) {
                    await planService.edit(this.$route.params.id, this.item)
                } else {
                    await planService.add(this.item)
                }
                this.$dialog.message.success(this.$t('Success'))
                this.$router.push('/plans')
            } catch (error) {
                this.$dialog.message.error(error.message)
            }
            this.loading = false
        }
    },
}
</script>